export const POSITION_STATUS = {
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
    DRAFT: 'DRAFT',
    CREATED: 'CREATED',
    READ_ONLY: 'READ_ONLY'
};

export const isValidSchedulingPosition = (data) => {
    return data?.position_status !== POSITION_STATUS.CLOSED && data?.position_status !== POSITION_STATUS.DRAFT && data?.position_status !== POSITION_STATUS.READ_ONLY;
};

export const isValidPosition = (data) => {
    return data?.position_status !== POSITION_STATUS.DRAFT && data?.position_status !== POSITION_STATUS.READ_ONLY;
};